.contact-us-page {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
}


.contact-black-column {
    width: 100%;
    height: 100%;
    overflow-y: auto; 
}

.contact-white-column {
    width: 100%;
    height: 100%;
    overflow-y: auto; 
    padding: 20px; 
}

.content{
    margin-left: 100px;
    margin-right: 50px;
}

.contact-title{
    text-transform: uppercase;
    margin-top: 100px;
    margin-bottom: 100px;
}


.contact-ways{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.span-contact{
    text-transform: uppercase;
    color: black;
    margin-left: 15px;
    font-weight: 700;
}

.p-contact{
    margin-top: 3px;
    font-size: 12px;
    font-weight: 600;
}

.times-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact-times{
    margin-top: 50px;
    font-size: 25px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .contact-us-page {
        background: white;
    }

    .contact-black-column  {
        display: none;
    }

    .contact-white-column {
        width: 100%; 
    }

    .content {
        margin-left: 5px; 
        margin-right: 5px; 
    }
}