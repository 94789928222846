.cookie-content{
    margin-left: 100px;
}

.terms-div{
    margin-top:50px;
   margin-bottom: 50px;
}

@media (max-width: 768px) {
    .cookie-content{
        margin-left: 10px;
    }
}