.section-news{
background-color: #1b1b1b;
width: 100%;
height: 90vh;
position: relative;
}

.kyveli__news{
    color: red;
    font-weight: 600;
    font-size: 18px;
    /* transform: rotateY(90deg); */
}
.news-item {
display: flex;
flex-direction: column;
align-items: center;
}

.news-video, .news-image
{
    position: static;
    width: 95vh;
    height: 100%;
    object-fit: contain;
    transition: filter 0.2s linear 0s;
    margin-top: 40px;
}

.news-name{
color: white;
font-size: 25px;
margin-top: 10px;
font-weight: 700;
}

.news-image-name, .news-video-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.discover {
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-size: 17px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
}

.discover:hover {
    color: black;
}

.discover::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.3s ease;
    padding-top: 10px;
    transition: all 0.3s 0.1s;
    z-index: -1;
}

.discover:hover::before {
    top: 0;
}


@media screen and (max-width: 768px) {

    .section-news{
       height: 50vh;
    }
    .news-video, .news-image{
        width: 45vh;
    }
    .news-name {
        font-size: 17px;
    }

    .news-image-name, .news-video-name{
        margin-top: 0px;
    }

    .discover{
    font-size: 14px;

    }
}

@media screen and (max-width: 432px ) {
    .news-video-name {
        width: 100%;
        padding: 0 10px;
    }
}




/*
.section-news .slick-prev,
.section-news .slick-next {
color: white;
font-size: 24px;
}

.section-news .slick-prev:before,
.section-news .slick-next:before {
font-family: "slick";
font-size: 24px;
line-height: 1;
}

.section-news .slick-prev {
left: 20px;
z-index: 1;
}

.section-news .slick-next {
right: 20px;
z-index: 1;
}

.news-item {
text-align: center;
}

.news-name {
color: white;
font-size: 18px;
}

.news-image,
.news-video {
max-width: 100%;
max-height: 40vh;
margin: 0 auto;
}

.news-image-name,
.news-video-name {
margin-bottom: 20px;
} */
