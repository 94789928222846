
.size-guide-page {
    direction: ltr;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.719) 50%, white 50%);
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
}


.black-column {
    width: 50%;
    height: 100%;
    overflow-y: auto; 
}

.white-column {
    width: 50%;
    height: 100%;
    overflow-y: auto; 
    padding: 20px; 
}
.content{
    margin-left: 40px;
    margin-right: 40px;
}

.ready-radiobuttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Custom styles for radio buttons */
input[type="radio"] {
    appearance: none;
    border: 1px solid black;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
}

input[type="radio"]:checked {
    background-color: black;
    border: 1px solid black;
    padding: 2px;
}

.radio-container {
    margin-left: auto; 
}

.radio-container label {
    margin-right: 10px;
}

.labels{
    font-weight: 600;
}

.size-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}


.size-table td {
    border: 2px solid lightgrey;
    padding: 8px;
    text-align: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
}

.size-table th {
    border: 2px solid lightgrey;
    padding: 8px;
    text-align: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 700;

}

.garments{
    font-size: 12px;
    color: grey;
}

.measurements{
    font-size: 12px;
    color: grey;
}

.center-container{
    display: flex;
    justify-content: center ;
    align-items: center; /* Center vertically */
}

.for-info{
    font-size: 14px;
}

.call-us-phone{
    display: flex;
    flex-direction: row;
}
.call-us{
    font-weight: 700;
    text-decoration: underline;
    
}
.phone-call{
    text-decoration: underline;
    margin-left: 15px;

}


@media (max-width: 768px) {
    .size-guide-page {
        background: white;
    }

    .black-column {
        display: none;
    }

    .white-column {
        width: 100%; 
    }

    .content {
        margin-left: 5px; 
        margin-right: 5px; 
    }

    .size-table-container{
        overflow-x: auto;
    }
}
