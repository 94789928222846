.login-center{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 50px;
overflow: hidden;
}
.email-h2{
text-align: start;
margin-bottom: 0;
}
.email-input{
width: 99%;
height: 55px;
box-sizing: border-box;
padding: 10px;
border: 1px solid black;
}

.start{
display: flex;
flex-direction: column;
align-items: center;
gap:20px;
width: 470px;
}

.next-button{
background-color: black;
width: 100%;
height: 55px;
color: white;
border: none;
font-size: 16px;
text-transform: uppercase;
font-weight: 600;
cursor: pointer;
}

.hr-email{
width: 100%;
border: 1px solid lightgray;
margin-top: 40px;
}


.p-text{
    font-size: 16px;
    margin-bottom: 15px;
}


.p-text-bold{
  font-size: 15px;
  font-weight: 800;
}

.icons-login{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
}

.text-icon{
  font-style: normal;
  font-size: 14px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .start {
    width: 90%;
    padding: 10px;
  }
  .icons-login {
    width: 90%;
  }
}
