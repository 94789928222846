.filter-page{
margin-top: 50px;
}

.filter-buttons-container {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
}

.clear-apply-filters {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 75px;
margin-left: 50px;
margin-right: 50px;
}

.clear-filter, .apply-filter {
background: none;
border: none;
cursor: pointer;
font-size: 16px;
font-weight: bolder;
text-transform: uppercase;
}

.clear-filter {
text-decoration: underline;
}

.colors-div {
cursor: pointer;
margin-left: 50px;
margin-right: 50px;
}

.colors-columns {
column-count: 2;
}

.color-swatch {
width: 15px;
height: 15px;
border: 1px solid black;
border-radius: 50px;
margin-right: 10px;
}

.products-color-name {
font-size: 14px;
}

.products-color-name:hover {
text-decoration: underline;
text-underline-offset: 0.4em;
}

.sizes-div {
/* height: 15vh; */
max-height: 15vh;
padding: 0 20px
/* margin-left: 50px; */
/* margin-right: 50px; */
}

.sizes {
cursor: pointer;
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 10px;
}

.products-size {
/* width: 25px; */
padding: 5px;
font-size: 16px;
text-align: center;
}

.products-websitename-title {
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* line-height: 20px; */
    min-height: 40px;       /* height is 2x line-height, so two lines will display */
    overflow: hidden;  /* prevents extra lines from being visible */
    /* width: 400px; */
}

@media (max-width: 750px) {
    .products-websitename-title{
        min-height: 40px;       /* height is 2x line-height, so two lines will display */
    }
  }


.products-size:hover {
text-decoration: underline;
text-underline-offset: 0.4em;
}

.products-size.active {
border: 1px solid black;
}

.products-size.active:hover {
text-decoration: none;
}

.price-div {
margin-left: 50px;
margin-right: 50px;
}

.price-slider {
color: black;
}

.price-slider-div {
display: flex;
flex-direction: row;
align-items: center;
gap: 15px;
}

.css-188mx6n-MuiSlider-root {
color: black !important;
}


.selected-count{
font-size: 12px;
}

.selected-symbol{
font-weight: 600;
margin-left: 10px;
}
