.banner-container {
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 15px;
  }

.banner-image, .banner-video {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

  .carousel{
    z-index: 1;
  }

.banner-image-name, .banner-video-name{
    position: relative;
}

.banner-name{
  position:absolute;
  top: 5px;
  left: 5px;
  padding: 10px 20px;
  z-index: 1;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);

}


@media screen and (max-width: 768px) {

    .banner-container {
        margin-top: 15px;
        margin-left:20px;
        margin-right: 20px;
      }


      .banner-image, .banner-video {
        height: 220px;
      }


.banner-name{
  position:absolute;
  /* top: 0;
  left:0; */
  z-index: 1;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
}
